import { TYPE_BROADCAST } from './types'
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_INVITATION]: `Dear Doctor Mr/Mrs/Ms, 
*{{GUEST_NAME}}* 

With honor we are inviting you to our Hippocratic Oath Batch I 2024 School of Medicine and Health Sciences Atma Jaya Catholic University of Indonesia.

Date: January 31st, 2024
Time: 08.30 WIB
Place: Auditorium Yustinus Lantai 15
Universitas Katolik Indonesia Atma Jaya (Kampus Semanggi)
Jl. Garnisun 1 No. 1 5, RT 05 RW 04, Karet Semanggi, Kec. Setiabudi, Kota Jakarta Selatan, DKI Jakarta 12930

Follow the link for more information details:
{{LINK}}

Thank you for your attention.`,

  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

*Tanpa mengurangi rasa hormat, mohon klik link dibawah ini untuk mendapatkan informasi lengkap mengenai acara pernikahan kami:*
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami, pada hari ini kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dan turut memberikan doa secara langsung dalam acara kami: 

*{{BOY_NAME}}*
{{BOY_FATHER_NAME_ID}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}*
{{GIRL_FATHER_NAME_ID}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}* di {{BROADCAST_WEDDING_LOCATION}}.

Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam acara pernikahan kami. 

Mohon pengertiannya untuk segala protokol kesehatan yang berlaku. Semoga kita semua selalu dalam keadaan sehat. 

Sampai jumpa di acara pernikahan kami!

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

  //Reminder RSVP
  [TYPE_BROADCAST.ID_REMINDER_RSVP] : `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melanjutkan undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, kami ingin mengingatkan Bapak/Ibu/Saudara/i untuk segera mengisi formulir konfirmasi kehadiran (RSVP) yang ada pada website undangan kami:
{{LINK}} 

Status kehadiran Bapak/Ibu/Saudara/i akan bermanfaat untuk kami dalam mempersiapkan acara pernikahan mendatang. 

Terima kasih atas perhatiannya.

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_
`,

[TYPE_BROADCAST.EN_REMINDER_RSVP]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Following up on our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, we would like to remind you to immediately fill out the attendance confirmation form (RSVP) on our invitation website: 
{{LINK}}

Your attendance status will be very useful for us in preparing the upcoming wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_
`,

[TYPE_BROADCAST.ID_REMINDER_EVENT]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melalui pesan ini, kami ingin mengingatkan kembali undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* yang diselenggarakan pada: 

Hari: {{BROADCAST_WEDDING_DAY}}
Waktu: {{TIME}} 
Lokasi: {{BROADCAST_WEDDING_LOCATION}}

Besar harapan kami Bapak/Ibu/Saudara/i berkenan menghadiri pernikahan kami.

Terima kasih 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_

`,
[TYPE_BROADCAST.EN_REMINDER_EVENT]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Through this message, we would like to remind you of our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}* which will be held on:

Date: {{BROADCAST_WEDDING_DAY}}
Time: {{TIME}}
Place: {{BROADCAST_WEDDING_LOCATION}}

We hope that you are willing to attend our wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_
`,

[TYPE_BROADCAST.ID_THANKS]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* dan keluarga ingin mengucapkan terima kasih atas kesediaan Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami. 

Kehadiran Bapak/Ibu/Saudara/i tentunya telah menjadi kebahagiaan untuk kami & keluarga. Kami juga ingin memohon maaf jika selama berlangsungnya acara terdapat hal-hal yang kurang berkenan. 
 
Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_

`,

[TYPE_BROADCAST.EN_THANKS]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

We, *{{BOY_NAME}}* & *{{GIRL_NAME}}* and family would like to thank you for your willingness to attend our wedding.

Your presence has certainly been a joy for us & our family. We also want to apologize if during the event there are things that are not pleasing.
 
Thank you for all the words, prayers, and attention given.

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_

`,


}